<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-row>
                <el-col :lg="24">
                    <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                        <el-row>
                            <el-col :lg="24" style="line-height: 27px;">
                                <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：打印发货单</strong>
                            </el-col>
                        </el-row>
                    </div>
                    <el-row style="margin-bottom: 20px">
                        <el-button v-if="$_getActionKeyListBoolean(['/shippingAdvice/lodopPrint'])" type="primary"
                                   size="mini" @click="lodopPrint" style="margin-bottom: 2px;margin-top: 2px">针式插件打印
                        </el-button>
                    </el-row>
                </el-col>
            </el-row>
            <div style="margin-right: 50px;float: left" v-for="index of pageSize" :key="index">
                <div id="myMain" style="width: 100%;margin-bottom: 100px" ref="myMain">
                    <div :id="'tableDataMain'+index">
                        <div class="topHtml">
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 60mm;margin-left: 48mm">
                                    <strong style=" font-size: 8mm ">过号重排</strong>
                                </div>
                                <div class="my-list-col" style="width: 35mm;">
                                    <strong style=" font-size: 8mm " class="carNumber">自动</strong>
                                </div>
                            </div>
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 85mm;">
                                    <strong style=" font-size: 8mm ">GT发货单</strong>
                                    <img border="0" src="@/assets/test.png" v-if="$store.getters.getRunTimeTest"
                                         style="z-index: -1; position: absolute; left:100px; top:200px;"/>
                                    <img border="0" src="@/assets/zf.png" v-if="formData.is_cancel"
                                         style="z-index: -1; position: absolute; left:200px; top:150px;"/>
                                </div>
                                <div class="my-list-col" style="width: 85mm;">
                                    <a>{{formData.bill_id_fh}}</a>
                                </div>
                            </div>
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 120mm;">
                                    <strong style=" font-size: 6mm ">客户:{{formData.customer}}</strong>
                                </div>
                            </div>
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 15mm;font-size: 6mm"><strong>备注:</strong></div>
                                <div class="my-list-col" style="width: 110mm;font-size: 6mm; "><strong
                                        style="font-size: 5mm">
                                    {{formData.remark_warehouse_bill?formData.remark_warehouse_bill +'。':'&nbsp;'}}</strong>
                                </div>
                            </div>
                        </div>
                        <table border="1" cellspacing="0" cellpadding="0" style="text-align: center;width: 130mm">
                            <tr>
                                <th style="width: 10mm;">序号</th>
                                <th style="width: 50mm;">规格</th>
                                <th style="width: 15mm;">数量</th>
                                <!--<th style="width: 15mm;">实厚</th>-->
                                <th style="width: 55mm;">备注</th>
                                <th style="width: 13mm;">仓库</th>
                            </tr>
                            <tr v-for="item in table[index-1]" :key="item.order_id" style="line-height: 7mm;">
                                <td>{{item.order_id}}</td>
                                <td><strong style=" font-size: 5mm ">{{item.specification}}</strong></td>
                                <td><strong style=" font-size: 5mm ">{{item.single_count_str}}</strong></td>
                                <!--<td></td>-->
                                <!--<td><strong style=" font-size: 5mm ">{{item.actual_thickness}}</strong></td>-->
                                <td>{{item.category != '方管' && item.category != '矩管' ? item.category : ''}}
                                    {{item.remark_stock}} {{item.full_thick !='' && item.full_thick != null ? '，'+
                                    $_getDataByBlank(item.full_thick) : ''}}
                                </td>
                                <td><strong style=" font-size: 5mm ">{{item.warehouse_out}}</strong></td>
                            </tr>
                        </table>
                        <div class="bottomHtml">
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 15mm;"><a>复核人:</a></div>
                                <div class="my-list-col" style="width: 25mm; border-bottom: 0.5mm solid #000000; "><a>
                                    {{formData.reviewer?formData.reviewer:'&nbsp;'}}</a></div>
                                <div class="my-list-col" style="width: 15mm;"><a>业务员:</a></div>
                                <div class="my-list-col" style="width: 25mm; border-bottom: 0.5mm solid #000000; "><a>
                                    {{formData.sales_man ?formData.sales_man :'&nbsp;'}}</a></div>
                                <div class="my-list-col" style="width: 15mm;"><a>发货人:</a></div>
                                <div class="my-list-col" style="width: 25mm; border-bottom: 0.5mm solid #000000; "><a>
                                    {{formData.shipper?formData.shipper:'&nbsp;'}}</a></div>
                            </div>
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 15mm;"><a>车号:</a></div>
                                <div class="my-list-col" style="width: 105mm; border-bottom: 0.5mm solid #000000; "><a>
                                    {{formData.car_code?formData.car_code :'&nbsp;'}}</a></div>
                            </div>

                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 85mm;">
                                    <a>注：★发单开出两天内有效。</a>
                                </div>
                                <div class="my-list-col" style="width: 35mm;">
                                    <a>C区：28979080</a>

                                </div>
                            </div>
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 85mm;">
                                    <a>打印日期:{{formatDate(new Date(),'yyyy年MM月dd日 HH:mm:ss')}}</a>
                                    <!--<a style="margin-left: 10mm;">订单日期:{{formatDate(formData.bill_date_kdtzs ,'yyyy年MM月dd日')}}</a>-->
                                </div>
                                <div class="my-list-col" style="width: 35mm;">
                                    <a>第{{index}}页，共{{pageSize}}页。</a>
                                </div>
                            </div>
                            <div class="my-list-row">
                                <div class="my-list-col" style="width: 100%;">
                                司机开单后在休息区把单交给发货员，不能擅自进入装卸区与仓库。
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </el-main>
    </div>
</template>

<script>


    export default {

        data() {
            return {
                table: [],
                tableDataMain: [],
                checkList: [],
                pageSize: 1,
                dataSize: 0,
                clearingFormList: ['微信'],
                formData: {
                    clearing_form: '欠款',
                    other_expenses : '费用',
                    other_expenses_price : '',
                    customer_phone: '',
                    car_code : '',
                    bill_date: '',
                    bill_date_kd : '',
                    bill_date_dd : '',
                    bill_date_kdtzs : new Date(),
                    contract_id : '',
                    delivery_date: '',
                    kick_back : '',
                    total_theoretical_weight: '',
                    bill_id_dd: '',
                    bill_id_kd : '',
                    bill_id_kdtzs : '',
                    bill_id: '',
                    settlement_days: '',
                    customer: '',
                    sales_man : '',
                    billing_company_name: '',
                    remark_warehouse_bill: '',
                    registrar: '',
                    total_amount: '',
                    practical_total_price : '',
                    total_number: '',
                    is_ticket: '',
                    last_name_warehouse_bill: '',
                    last_date_warehouse_bill: '',
                    bill_type: '发货单',
                    creat_date_warehouse_bill : '',
                    handled_by: '',
                    consignee: '',
                    tax_tate_warehouse_bill : '',
                    supplier: '',
                    additional_charges : '',
                    amount_of_product : '',
                    cost_of_the_unit : '',
                    total_weighing_heavy : '',
                    pounds_fees : '',//磅费
                    wind_cut_fees : '',//风割费
                    loading_car_fees : '',//装车费
                    transfer_fees : '',//中转费
                    freight_fees : '',//运费
                    taxes_fees : '',//税金
                    is_cancel: false,
               },
                input: ''
           }
       },
        methods: {
            lodop() {
                //打印单
                const LODOP = this.$getLodop()
                const strStyle = "<style>.my-list-row {display: inline-block; width: 100%;} .my-list-col {float: left; width: 33.33%; height: 28px; line-height: 28px;} .my-top,.my-bottom {font-size: 12px;} .my-top {margin-bottom: 5px;} .my-bottom {margin-top: 30px; text-align: right;}</style>"
                LODOP.SET_PRINT_PAGESIZE(1, 2100, 1400, "");//这里3表示纵向打印且纸高“按内容的高度”；1385表示纸宽138.5mm；45表示页底空白4.5mm
                for (let i = 1; i < this.pageSize + 1; i++) {
                    if (i > 1) {
                        LODOP.NewPageA();
                   }
                    LODOP.ADD_PRINT_HTM(15, 5, "100%", "100%","<!DOCTYPE html>"+ strStyle + "<body>" + document.getElementById("tableDataMain" + i).innerHTML + "</body>");
                    LODOP.SET_PRINT_STYLEA(0, "AngleOfPageInside", 90);
               }
                return LODOP;
           },
            lodopPrint() {
                //执行派号接口
                this.$axios({
                    method: 'post',
                    url: '/admin/common/printCarNumber',
                    params: {
                        only_bill_id : this.formData.only_bill_id
                    }
               }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        let carNumber = response.data.carNumber;
                        var myElements = document.getElementsByClassName("carNumber");
                        //console.log(myElements);
                        for (let i = 0; i < myElements.length; i++) {
                            myElements[i].innerHTML = carNumber;
                            //console.log(myElements[i])
                       }
                        const LODOP = this.lodop()
                        LODOP.PREVIEW()
                        //LODOP.PRINT_SETUP();//打印维护
                        //LODOP.PRINT_DESIGN()
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            splitTableDataMain() {
                let list = [];
                for (let i = 0; i < this.tableDataMain.length; i++) {
                    list.push(this.tableDataMain[i]);
                    if (list.length == 15) {
                        this.table.push(list);
                        list = [];
                   }
               }
                this.table.push(list);
           },
            indexMethod(index) {
                return index + 1;
           },
            searchWarehouseBill() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios({
                    method: 'post',
                    url: '/admin/shippingAdvice/search',
                    params: {
                        bill_id_fh : this.formData.bill_id_fh,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.warehouseBill;
                        this.tableDataMain = this.$_createRowData(response.data.stocks);
                        this.dataSize = response.data.dataSize;
                        this.checkList = [];
                        if (this.formData.clearing_form == '欠款') {
                            this.checkList.push('欠款单')
                       } else {
                            this.checkList.push('调拨单')
                       }
                        this.clearingFormList = [];
                        if (this.formData.clearing_form != null && this.formData.clearing_form != '') {
                            if (this.formData.clearing_form.indexOf('+') > 0) {
                                let s = this.formData.clearing_form.split('+');
                                for (let i = 0; i < s.length; i++) {
                                    this.clearingFormList.push(s[i])
                               }
                           } else {
                                this.clearingFormList.push(this.formData.clearing_form)
                           }
                       }
                        if (this.tableDataMain.length > 0){
                            this.pageSize = this.dataSize % 15 == 0 ? this.dataSize / 15 : this.$XEUtils.toInteger((this.dataSize / 15)) + 1;
                            this.splitTableDataMain();
                        }
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
       },
        created() {
            this.formData.bill_id_fh = this.$route.query.bill_id_fh;
            this.searchWarehouseBill();
       }
   }


</script>

<style scoped>
    .my-list-row {
        display: inline-block;
        width: 100%;
   }

    .my-list-col {
        float: left;
        width: 33.33%;
        line-height: 28px;
   }

    .my-top, .my-bottom {
        font-size: 12px;
   }

    .my-top {
        margin-bottom: 5px;
   }

    .my-bottom {
        margin-top: 30px;
        text-align: right;
   }
</style>
